import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";

import mediaQuery from "src/assets/styles/mediaQuery";

const BtnBasic = styled.a`
  font-size: ${typographySizes.s}px;
  color: ${(props) => (props.color ? props.color : colors.darkGrey)};
  line-height: 1.33;
  font-weight: 300;
  position: relative;
  cursor: pointer;
  padding: 10px 20px;
  box-shadow: -1px 5px 11px 0 rgba(0, 67, 175, 0.1);
  margin-top: 50px;
  display: inline-block;
  transition: all 0.5s;
  text-decoration: none;
  background-color: ${colors.lightenMainColor};
  :hover {
    transition: all 0.5s;
    color: ${colors.darkGrey};
    background-color: ${colors.white};
  }
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: ${typographySizes.xs}px;
  }
  @media (min-width: ${mediaQuery.max}) {
    font-size: ${typographySizes.maxS}vw;
    line-height: 1.33;

    padding: 0.693vw 1.386vw;
    box-shadow: -0.069vw 0.346vw 0.762vw 0 rgba(0, 67, 175, 0.1);
    margin-top: 3.466vw;
  }
`;

export default BtnBasic;
