import React from "react";
import { StaticQuery, graphql } from "gatsby";

import styled from "styled-components";
import Title from "src/components/typography/Title.js";
import BigClaim from "src/components/typography/BigClaim.js";
import Wrapper from "src/components/global/Wrapper.js";
import BtnBasic from "src/components/global/btn/BtnBasic.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery";

import typographySizes from "src/assets/styles/typographySizes";

const CtaWrapper = styled(Wrapper)`
  margin-top: 75px;
  margin-bottom: 115px;
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 5.199vw;
    margin-bottom: 7.973vw;
  }
`;

const Bottom = styled.div`
  margin-left: 50%;
  margin-top: 25px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 25%;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 1.733vw;
  }
`;

const Subtitle = styled(Title)`
  width: 66.66%;
  margin-bottom: 50px;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-bottom: 25px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 3.466vw;
  }
`;

const Btn = styled(BtnBasic)`
  background-color: ${colors.white};
  color: ${colors.darkGrey};
  font-size: ${typographySizes.sm}px;
  width: 174px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  padding: 0;
  margin-top: 0;
  :hover {
    transition: all 0.5s;
    color: ${colors.white};
    background-color: ${colors.lightenMainColor};
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.xs}px;
    width: 105px;
    height: 34px;
    line-height: 34px;
  }
  @media (min-width: ${mediaQuery.max}) {
    width: 12.063vw;
    height: 3.882vw;
    line-height: 3.882vw;
    font-size: ${typographySizes.maxS}vw;
  }
`;

const CtaComponent = ({ query }) => (
  <CtaWrapper>
    <BigClaim
      data-sal="slide-up"
      data-sal-easing="ease"
      data-sal-duration="1000"
    >
      {query.strapiCallUs.Title}
    </BigClaim>
    <Bottom>
      <Subtitle
        data-sal="slide-up"
        data-sal-easing="ease"
        data-sal-duration="1000"
      >
        {query.strapiCallUs.Subtile}
      </Subtitle>
      <Btn
        data-sal="slide-up"
        data-sal-easing="ease"
        data-sal-duration="1000"
        href={`tel:${query.strapiCallUs.Btn_link.Link}`}
      >
        {query.strapiCallUs.Btn_link.Name}
      </Btn>
    </Bottom>
  </CtaWrapper>
);

export default function Cta(props) {
  return (
    <StaticQuery
      query={graphql`
        query CtaQuery {
          strapiCallUs {
            Subtile
            Title
            Btn_link {
              Link
              Name
            }
          }
        }
      `}
      render={(data) => <CtaComponent query={data} />}
    />
  );
}
