import React from "react";

import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes.js";
import { Link } from "gatsby";

import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery";

import BtnArrowSvg from "src/assets/svg/btn-arrow.svg";

const BtnArrowWrapper = styled(Link)`
  font-size: ${typographySizes.s}px;
  color: ${(props) => (props.White ? colors.white : colors.darkGrey)};
  line-height: 1.33;
  font-weight: 300;
  position: relative;
  cursor: pointer;
  padding: 10px 0px;
  flex-shrink: 1;
  flex-grow: 0;
  display: inline-block;
  transition: all 0.5s;
  text-decoration: none;

  @media (max-width: ${mediaQuery.tablet}) {
    box-shadow: -1px 5px 11px 0 rgba(0, 67, 175, 0.1);
    background-color: #ffffff;
    padding: 15px 20px;
    text-align: center;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: ${typographySizes.xs}px;
  }
  @media (min-width: ${mediaQuery.max}) {
    font-size: ${typographySizes.maxS}vw;
    line-height: 1.33;

    .BtnArrowSvg {
      width: 0.756vw;
      height: 0.754vw;
    }
  }
  /* ::after {
    content: "";
    background-image: url(${BtnArrowSvg});
    position: absolute;
    background-size: cover;
    right: -10px;
    top: 50%;
    transform: translate(100%, -50%);
    transition: all 0.5s;
    transform-origin: center;
    height: 12px;
    width: 12px;
  } */
  .BtnArrowSvg {
    transform-origin: center;
    transition: all 0.5s;
    margin-left: 10px;
  }
  :hover {
    transition: all 0.5s;
    color: ${colors.mainColor};
    .BtnArrowSvg {
      transform: rotate(45deg);
      transition: all 0.5s;
    }
    /* ::after {
      top: 28%;
      transform: rotate(45deg) translate(100%, -50%);
      transition: all 0.5s;
    } */
  }
`;

const BtnArrow = ({ children, link }) => (
  <BtnArrowWrapper to={link}>
    {children} <BtnArrowSvg className="BtnArrowSvg" />
  </BtnArrowWrapper>
);

export default BtnArrow;
