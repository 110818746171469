import React from "react";
import styled from "styled-components";
import Wrapper from "src/components/global/Wrapper.js";
import colors from "src/assets/styles/colors.js";
import Img from "gatsby-image";
import BtnArrow from "src/components/global/btn/BtnArrow.js";
import ReactMarkdown from "react-markdown";
import SEO from "src/components/SEO.js"

import BodyText from "src/components/typography/BodyText.js";
import Title from "src/components/typography/Title.js";

import mediaQuery from "src/assets/styles/mediaQuery";

import Cta from "src/components/global/Cta.js";

const Bg = styled.div`
  background-color: ${colors.lightGrey};
  width: 100%;
  position: relative;
  ::before {
    content: "";
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: ${colors.white};
    z-index: 0;
    transform: translateY(0px);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    ::before {
      height: 40vw;
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    ::before {
      height: 6.93vw;
    }
  }
`;

const Header = styled.header`
  padding-top: 281px;
  height: calc(100vh - 281px);
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column-reverse;
    height: auto;
    align-items: flex-start;
    padding-top: 100px;
  }
  @media (min-width: ${mediaQuery.max}) {
    padding-top: 19.473vw;
    height: calc(100vh - 19.473vw);
  }
`;

const HeaderImgWrapper = styled(Img)`
  position: relative;
  height: 100%;
  width: 58.33%;
  display: inline-block;

  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(50% - 10px);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const HeaderInfo = styled.div`
  width: 33.33%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    margin-bottom: 15px;
  }
`;

const Designer = styled(BodyText)`
  color: ${colors.mainColor};
  margin-top: 50px;
  margin-bottom: 50px;
  display: inline-block;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 3.465vw;
    margin-bottom: 3.465vw;
  }
`;

const DesignerMobile = styled(BodyText)`
  display: none;
  color: ${colors.mainColor};
  margin-top: 20px;
  align-self: flex-end;
  @media (max-width: ${mediaQuery.tablet}) {
    display: inline-block;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 1.386vw;
  }
`;

const TopImagesWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;

  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    margin-top: 60px;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 1.386vw;
  }
`;

const TopImageWrapper = styled.div`
  width: calc(33.33% - 13.33px);
  margin-right: 20px;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-bottom: 10px;
    :last-of-type {
      margin-bottom: 0;
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-right: 1.386vw;
      width: calc(33.33% - 0.923vw);

  }
`;

const AssumptionWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 10px;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    flex-direction: column;
  }

  @media (min-width: ${mediaQuery.max}) {
    margin-top: 3.466vw;
  }
`;

const AssumptionCol = styled(BodyText)`
  width: calc(33.33% - 13.33px);
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-bottom: 20px;
    :last-of-type {
      margin-bottom: 0;
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    width: calc(33.33% - 0.924vw);
  }
`;

const MidWrapper = styled.div`
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 60px;
    flex-direction: column;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 10.399vw;
  }
`;

const MidInfoCol = styled.div`
  width: 33.33%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const MidImg = styled(Img)`
  width: calc(66.66% - 10px);
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
  @media (min-width: ${mediaQuery.max}) {
    width: calc(66.66% - 0.693vw);
  }
`;

const MidTitle = styled(Title)`
  max-width: 250px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 15px;
  }
  @media (min-width: ${mediaQuery.max}) {
    max-width: 17.332vw;
  }
`;

const MidPeroson = styled(BodyText)`
  color: ${colors.mainColor};

  margin-top: 150px;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 10.399vw;
  }
`;

const MidPerosonMobile = styled(BodyText)`
  color: ${colors.mainColor};
  display: none;
  margin-top: 150px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 20px;
    align-self: flex-end;
    display: inline-block;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 10.399vw;
  }
`;

const BottomWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 1.386vw;
  }
`;

const BottomCol = styled.div`
  width: calc(33.33% - 13.33px);
  :last-of-type {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-bottom: 20px;
    :last-of-type {
      margin-bottom: 0;
    }
  }
  .marginP {
    p {
      margin-bottom: 20px;
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    width: calc(33.33% - 0.924vw);
    .marginP {
      p {
        margin-bottom: 1.386vw;
      }
    }
  }
`;

const BottomCaption = styled(BodyText)`
  margin-top: 20px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 10px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 1.386vw;
  }
`;

const BottomLocalisation = styled(BodyText)`
  color: ${colors.mainColor};
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 20px;
    align-self: flex-end;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 1.386vw;
  }
`;

const BtnSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0;
  @media (max-width: ${mediaQuery.tablet}) {
    margin: 50px 0;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin: 6.933vw 0;
  }
`;

const ProjectPage = ({ data }) => {
  return (
    <> 
     <SEO title={data.strapiHighlightedProjects.Name}
        description={data.strapiHighlightedProjects.Description_header}/>
      <Bg>
        <Wrapper>
          <Header>
            <DesignerMobile
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              {data.strapiHighlightedProjects.Designer}
            </DesignerMobile>

            <HeaderImgWrapper
              fluid={
                data.strapiHighlightedProjects.Top_image.childImageSharp.fluid
              }
            />

            <HeaderInfo>
              <Title
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                {data.strapiHighlightedProjects.Name}
              </Title>
              <Designer
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                {data.strapiHighlightedProjects.Designer}
              </Designer>
            </HeaderInfo>
          </Header>

          <TopImagesWrapper>
            {data.strapiHighlightedProjects.Top_images.map((document) => (
              <TopImageWrapper
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <Img fluid={document.localFile.childImageSharp.fluid} />
              </TopImageWrapper>
            ))}
          </TopImagesWrapper>
          <AssumptionWrapper>
            <AssumptionCol
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              {data.strapiHighlightedProjects.Title_assumption}
            </AssumptionCol>
            <AssumptionCol
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              {data.strapiHighlightedProjects.Firs_col_assumption}
            </AssumptionCol>
            <AssumptionCol
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              {data.strapiHighlightedProjects.Second_col_assumption}
            </AssumptionCol>
          </AssumptionWrapper>
          <MidWrapper>
            <MidInfoCol>
              <MidTitle
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                {data.strapiHighlightedProjects.MidSection_title}
              </MidTitle>
              <MidPeroson
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                {data.strapiHighlightedProjects.MidSection_person}
              </MidPeroson>
            </MidInfoCol>
            <MidImg
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
              fluid={
                data.strapiHighlightedProjects.MidSection_img.childImageSharp
                  .fluid
              }
            />
            <MidPerosonMobile
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              {data.strapiHighlightedProjects.MidSection_person}
            </MidPerosonMobile>
          </MidWrapper>
        </Wrapper>
      </Bg>
      <Wrapper>
        <BottomWrapper>
          <BottomCol
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <Img
              fluid={
                data.strapiHighlightedProjects.BottomSection_img_before
                  .childImageSharp.fluid
              }
            />
            <BottomCaption>
              {data.strapiHighlightedProjects.BottomSection_title_before}
            </BottomCaption>
          </BottomCol>
          <BottomCol
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <Img
              fluid={
                data.strapiHighlightedProjects.BottomSection_img_after
                  .childImageSharp.fluid
              }
            />
            <BottomCaption>
              {data.strapiHighlightedProjects.BottomSection_title_after}
            </BottomCaption>
          </BottomCol>
          <BottomCol
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <BodyText>
              <ReactMarkdown
                className={"marginP"}
                source={
                  data.strapiHighlightedProjects.BottomSection_description
                }
              />
            </BodyText>
            <BottomLocalisation>
              {data.strapiHighlightedProjects.BottomSection_localisation}
            </BottomLocalisation>
          </BottomCol>
        </BottomWrapper>
        <BtnSectionWrapper
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <BtnArrow link="/realizacje">zobacz więcej realizacji</BtnArrow>
        </BtnSectionWrapper>
      </Wrapper>
      <Cta />
    </>
  );
};

export const query = graphql`
  query ProjectLayout($id: String!) {
    strapiHighlightedProjects(id: { eq: $id }) {
      Name
      Designer
      Description_header
      Firs_col_assumption
      Second_col_assumption
      Title_assumption
      Top_image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Top_images {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      MidSection_person
      MidSection_title
      MidSection_img {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BottomSection_description
      BottomSection_img_after {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BottomSection_img_before {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BottomSection_localisation
      BottomSection_title_after
      BottomSection_title_before
    }
  }
`;

export default ProjectPage;
